import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/serialize';

export const extendStyles = (styles: string | SerializedStyles | undefined): SerializedStyles | string => {
  if (!styles) {
    return '';
  }

  if (typeof styles === 'string') {
    return css`
      ${styles}
    `;
  } else if (styles.styles) {
    // It's the intance of SerializedStyles
    return styles;
  } else {
    return '';
  }
};

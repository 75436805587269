import React from 'react';

import { Button, ButtonProps } from 'rebass';

interface VozziPrimaryButtonPropsI extends ButtonProps {
  //
}

export const VozziPrimaryButton: React.FC<VozziPrimaryButtonPropsI> = (props) => {
  const newProps = { ...props } as any;
  if (!props.p && !props.px && !props.py) {
    newProps.p = '12px 24px';
  }
  return (
    <Button
      variant="primary"
      disabled={props.disabled}
      style={props.disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
      {...newProps}
    >
      {props.children}
    </Button>
  );
};

import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import { PageSectionStyled, PageSectionStyledPropsI } from './css/PageSectionStyled.css';
import { container } from '../../../../shared-styles/container.css';
import { css } from '@emotion/core';

interface PageSectionPropsI extends PageSectionStyledPropsI {}

export const PageSection: React.FC<PageSectionPropsI> = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        backgroundImage: allFile(filter: { relativeDirectory: { eq: "bg" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 2480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  const styleBackgroundImage = css`
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  `;

  if (props.backgroundImage) {
    const backgroundImage = data.backgroundImage.edges.find(({ node }: any) => node.relativePath === props.backgroundImage);

    if (backgroundImage && backgroundImage.node) {
      const newProps = { ...props };
      delete newProps.backgroundImage;
      return (
        <BackgroundImage
          Tag="section"
          fluid={backgroundImage.node.childImageSharp.fluid}
          className={props.className}
          css={styleBackgroundImage}
        >
          <PageSectionStyled {...newProps} as="div">
            <div css={props.fullWidth ? container : ``}>{props.children}</div>
          </PageSectionStyled>
        </BackgroundImage>
      );
    }
  }

  return (
    <PageSectionStyled {...props}>
      <div css={props.fullWidth ? container : ``}>{props.children}</div>
    </PageSectionStyled>
  );
};

import Swal from 'sweetalert2';

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

export const customToastError = (title: string, text: string): void => {
  Toast.fire({
    icon: 'error',
    title: title,
    text: text,
  });
};

export const genericToastError = (): void => {
  Toast.fire({
    icon: 'error',
    title: 'Error:',
    text: 'Oops! Something went wrong.',
  });
};

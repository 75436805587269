import React from 'react';

import { TopNavigationMenuStyled } from './css/TopNavigationMenuStyled.css';

import { ResponsiveContainer } from '../../../functional/ResponsiveContainer.functional';
import MobileNav from './MobileTopNav';
import DesktopNav from './DesktopTopNav';

export const TopNavigationMenu: React.FC = () => {
  return (
    <TopNavigationMenuStyled>
      <ResponsiveContainer breakpoint="lg">
        {(matches: any): JSX.Element => (
          <>
            {matches.mobile && <MobileNav />}
            {matches.desktop && <DesktopNav />}
          </>
        )}
      </ResponsiveContainer>
    </TopNavigationMenuStyled>
  );
};
